import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const categoryAbbreviations = {
  bw: "Balance & Wellbeing",
  bp: "Benefits & Perks",
  cp: "Career Progression",
  cc: "Company Culture",
  di: "Diversity & Inclusion",
  it: "Innovation & Technology",
  js: "Job Satisfaction",
  ld: "Learning & Development",
  mo: "Management & Organization",
  mp: "Mission & Purpose",
  r: "Remuneration",
  tp: "Team & People",
};

export const categoryMap: { [key: string]: string } = categoryAbbreviations;

/**
 * This function is used for converting string to capitalized form
 * @param str
 * @returns
 */
const capitalize = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export function getCategoryLabel(category: string) {
  if (category.length > 2) {
    return capitalize(category);
  }
  return categoryMap[category];
}

export function formatToCode(attribute: string): string {
  return attribute
    .trim() // Remove extra spaces from start and end
    .toLowerCase() // Convert to lowercase
    .replace(/\s*&\s*/g, "_&_") // Convert " & " to "_&_", accounting for possible spaces or newline characters
    .replace(/[\s\n]+/g, "_"); // Convert spaces or newline characters to underscores
}

export function codeToFormat(code: string = ""): string {
  return code
    .replace(/_/g, " ") // Convert underscores to spaces
    .replace(/ _&_ /g, " & ") // Convert "_&_" to " & "
    .replace(/\b\w/g, (char) => char.toUpperCase()) // Capitalize the first letter of each word
    .trim(); // Remove extra spaces from start and end if any
}

// Function to generate dynamic color palette based on data length
export function generateChartColorPalette(length: number) {
  const baseColors = [
    "#62B2FD",
    "#F99BAB",
    "#C4B5FD",
    "#60A5FA",
    "#22D3EE",
    "#2DD4BF",
    "#9BDFC4",
    "#6EE7B7",
    "#BEF264",
    "#FCD34D",
  ];

  const colors = [];

  for (let i = 0; i < length; i++) {
    const baseColor = baseColors[i % baseColors.length];

    // Convert hex to RGB
    const bigint = parseInt(baseColor.replace(/^#/, ""), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    // Create a lighter shade by increasing RGB values
    const lightenedColor = `#${((1 << 24) + (Math.min(255, r + 30) << 16) + (Math.min(255, g + 30) << 8) + Math.min(255, b + 30)).toString(16).slice(1).toUpperCase()}`;

    colors.push(lightenedColor);
  }

  return colors;
}

export function generateBarStyle(baseColor: string) {
  function darkenColor(hex: string, amount: number) {
    // Remove # if present
    let color = hex.startsWith("#") ? hex.slice(1) : hex;

    // Convert hex to RGB
    let num = parseInt(color, 16),
      r = Math.max(0, Math.min(255, (num >> 16) - amount)),
      g = Math.max(0, Math.min(255, ((num >> 8) & 0x00ff) - amount)),
      b = Math.max(0, Math.min(255, (num & 0x0000ff) - amount));

    // Convert back to hex and return
    return `#${[r, g, b].map((x) => x.toString(16).padStart(2, "0")).join("")}`;
  }

  return darkenColor(baseColor, 30); // Darken the color by 30
}
