import { cn } from "@ui/lib/utils";
import { ReactNode } from "react";

interface Props {
  children: ReactNode;
  className?: string;
}
function PageFlexCol({ children, className }: Props) {
  return (
    <div
      className={cn(
        "flex-grow flex flex-col gap-6 md:gap-8 p-4 md:p-8 min-h-fit",
        className
      )}
    >
      {children}
    </div>
  );
}

export { PageFlexCol };
